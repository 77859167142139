import React from 'react';
import { Link } from 'react-router-dom';

const GamblingSite = () => {
  const casinos = [
    { name: '500 Casino', url: '/500casino' },
    { name: 'CSGORoll', url: '/csgoroll' },
    { name: 'CSGOEmpire', url: '/csgoempire' },
    { name: 'ClashGG', url: '/clashgg' },
    { name: 'Gamdom', url: '/gamdom' },
    { name: 'Key-drop', url: '/keydrop' },
    { name: 'Hypedrop', url: '/hypedrop' },
    { name: 'Duelbits', url: '/duelbits' }
  ];

  return (
    <div className="text-bg">
      <div className="text-container">
        <div className="text-card-container">
          <div className="text-card">
            <h1 className="header-marginbot">List of Top CS Gambling Sites</h1>
            
            <h2 className="header-left">Recommended CS Gambling Sites</h2>
            <span className="text-marginbot">
              Explore our curated list of top recommended sites for Counter-Strike gambling. Each of these platforms offers a unique gaming experience, including various modes like Roulette, Crash, and Case Opening. Click on the individual links to learn more and start your CS gambling journey today.
            </span>
            <ul>
              {casinos.map((casino, index) => (
                <li key={index}>
                  <Link className="link-color" to={casino.url}>{casino.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamblingSite;