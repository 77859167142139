import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const casinos = [
    { name: '500 Casino', url: '/500casino' },
    { name: 'CSGORoll', url: '/csgoroll' },
    { name: 'CSGOEmpire', url: '/csgoempire' },
    { name: 'ClashGG', url: '/clashgg' },
    { name: 'Gamdom', url: '/gamdom' },
    { name: 'Key-drop', url: '/keydrop' },
    { name: 'Hypedrop', url: '/hypedrop' },
    { name: 'Duelbits', url: '/duelbits' }
  ];

  return (
    <div className="text-bg">
      <div className="text-container">
        <div className="text-card-container">
          <div className="text-card">
            <h1 className="header-marginbot">404: Page Not Found.</h1>
            <h2 className="header-marginbot">Looks like you are lost...</h2>
            <div className="button-container">
  <Link className="link-color" to="/"><button className="header-button">Go back home</button></Link>
    <Link className="link-color" to="/gamblingsites"><button className="header-button">All sites</button></Link>
    
  </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;