import React from 'react';
import './CasinoRatings.css';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import TextHomepage from './TextHome';

const SkinRating = () => {
  const generateStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    
    return (
      <>
        {Array.from({ length: fullStars }, (_, index) => (
          <StarIcon key={index} style={{ color: '#f0b40c', fontSize: '15px' }} />
        ))}
        {hasHalfStar && <StarHalfIcon style={{ color: '#f0b40c', fontSize: '15px' }} />}
      </>
    );
  };
  const casinos = [
    {
        name: 'lis-skins',
        logo: 'https://skinlords.com/wp-content/uploads/2022/11/lis-skins-logo.png',
        rating: 5,
        bonus: 'Deposit 100usd and get 5 for free!',
        description: 'Lis-skins is your go-to for budget-friendly CS2 skins. With $100 deposit, get an extra $5 for free. You have a multitude of options for both buying and selling CS2 skins.',
        link: 'https://lis-skins.ru/?rf=1220602',
        alt: 'lis-skins logo'
    },
    {
        name: 'BUFF market',
        logo: 'https://play-lh.googleusercontent.com/GZHKQ5-6rzCEXjAzzmZ_s1V9Y_54tHyLGCu3IkduHvYFYMxbRiBmHfpSVNQpVoyOn-Q',
        rating: 4,
        bonus: 'One lucky draw chance to 100% get a free skin.',
        description: 'BUFF market is the European answer to BUFF163, providing a wide range of CS2 skins.',
        link: 'https://buff.market/r/U1093150322',
        alt: 'BUFF market logo'
    },
    {
        name: 'TradeitGG',
        logo: 'https://skinlords.com/wp-content/uploads/2023/09/tradeit-gg-site-logo-135x135.png',
        rating: 4,
        bonus: 'Up to $5 on Your First Trade',
        description: 'TradeIt is a well-known CSGO skin trading bot facilitating over 45 million trades on its platform. It offers many features to help users find their desired skin, and allows them to instantly sell their skins for real money at a discounted price.        ',
        link: 'https://tradeit.gg/?aff=CSGamblingCC',
        alt: 'DMarket logo'
    },
    {
        name: 'CSGOEmpire',
        logo: 'https://pbs.twimg.com/profile_images/1587610938970087424/UzJPdbvL_400x400.png',
        rating: 3.5,
        bonus: 'Free Spin Up to $1,500 Redeem Code » CSGAMBLINGGG',
        description: 'CSGOEmpire offers a wide range of CS2 skins with quick P2P trading options.',
        link: 'https://csgoempire.com/r/CSGAMBLINGGG',
        alt: 'CSGOEmpire logo'
    },
];

  
  return (
    <div>
    <div className="casino-container">
      <h2 className="Header-content-margin">The Top Choices for buying CS Skins in 2023</h2>
      <p>Discover the Best CS:GO Skin Marketplaces for 2023 With Our Comprehensive List: Your One-Stop Resource for Top-Rated Buying and Selling Skins Experiences</p>
      <div className="casino-card-container">
      {casinos.map((casino, index) => (
        <div className="casino-card" key={index}>
          <img src={casino.logo || 'default_logo.png'} alt={`${casino.name} logo`} className="casino-logo" />
          <h4 className="casino-name">{casino.name}</h4>
          <div className="casino-description">{casino.description || 'No description available'}</div>
          <div className="casino-rating">
            {casino.rating ? (
              <>
                <span className="rating-label">Rating:</span>
                <div>{generateStars(casino.rating)}</div>
              </>
            ) : (
              <span className="rating-label">No rating</span>
            )}
          </div>
          <div className="casino-bonus-container">
            <span className="bonus-label">
             <p>Bonus</p></span>
            <span className="casino-bonus">
            <strong>{casino.bonus || 'No bonus available'}</strong>
            </span>
          </div>
          <a href={casino.link || '#'} target="_blank" rel="noopener noreferrer">
            <button>Visit Site</button>
          </a>
        </div>
      ))}
      </div>
    </div>
    <TextHomepage/>
    </div>
  );
  };
  
  export default SkinRating;
  