import React from 'react';
import { Link } from 'react-router-dom';
import "./Advertise.css"

const AdvertisingContact = () => {
  return (
    <div className="text-bg">
      <div className="text-container">
        <div className="text-card-container">
          <div className="text-card">
            <h1 className="header-marginbot">Advertise with CSGambling.cc: A Premium Partner for Your CS:GO Gambling and Sportbetting solutions</h1>

            <h3 className="header-left">Why Choose CSGambling.cc?</h3>
            <span className="text-marginbot">If your enterprise is involved in the CS:GO business, particularly in the area of CS:GO skin gambling or sportsbetting, CSGambling.cc serves as an ideal platform for your advertising needs. We cater to a robust and active user base, consistently receiving an average of 100 daily visitors in search of trustworthy and dependable CS:GO gambling platforms.</span>

            <h3 className="header-left">Our Offerings</h3>
            <span className="text-marginbot">We offer a diverse range of advertising packages tailored to meet our clients' specific requirements. However, please be advised that we maintain strict criteria for our advertising partners; we do not feature Crypto casinos unless they are directly related to CS:GO.</span>

            <h3 className="header-left">Contact Us</h3>
            <span className="text-marginbot">
             If you're interested in leveraging our platform for your advertising needs, kindly reach out to us via email at <a className="link-color" href="mailto:info@csgambling.cc">melkisbest@gmail.com</a>. Our team of experts is keen to work with you to customize a package that is just right for your target audience.
            </span>
            <h3 className="header-left">Customized Solutions</h3>
            <span className="text-marginbot">We understand that every client has unique needs. If our existing advertising packages don't align with your objectives, we invite you to email us. We would be pleased to discuss a customized advertising package that is better suited to your specific requirements.</span>

            <span className="text-marginbot">For more information, kindly refer back to our <Link className="link-color" to="/">home page</Link>.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertisingContact;






