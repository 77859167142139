import React from 'react';
import './CasinoRatings.css';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { Link } from 'react-router-dom';

const CasinoRatings = () => {
  const generateStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    
    return (
      <>
        {Array.from({ length: fullStars }, (_, index) => (
          <StarIcon key={index} style={{ color: '#f0b40c', fontSize: '15px' }} />
        ))}
        {hasHalfStar && <StarHalfIcon style={{ color: '#f0b40c', fontSize: '15px' }} />}
      </>
    );
  };
  const casinos = [
    {
        name: '500 Casino',
        logo: 'https://pbs.twimg.com/profile_images/1515314479596245000/WEkrgO5N_400x400.png',
        rating: 5,
        bonus: 'Get +100% on Your First Deposit Redeem Code » CSGamblingCC',
        description: 'CSGO500 is one of the best CS2 Gambling Sites. You can play Roulette, Wheel, Crash, Slots, and many more! Withdraw CS2 Skins, Crypto, or Game Keys!',
        link: 'https://500.casino/r/CSGAMBLINGCC',
        alt: '500 Casino logo'
    },
    {
        name: 'CSGORoll',
        logo: 'https://pbs.twimg.com/profile_images/1610084878720049154/n0j4nld9_400x400.png',
        rating: 5,
        bonus: '3 Free Spins Up to $1,000 Redeem Code » CSGamblingCC',
        description: 'CSGORoll is a well-known Roulette and CS2 Betting site. You can play Crash, Plinko and many other games! This site offers instant CS2 skin withdrawals. Open a case and get a chance to win a Dragon Lore!',
        link: 'https://csgoroll.com/r/CSGamblingCC',
        alt: 'CSGORoll logo'
    },
    // Add alt attributes for the remaining casinos here
    {
        name: 'CSGOEmpire',
        logo: 'https://pbs.twimg.com/profile_images/1587610938970087424/UzJPdbvL_400x400.png',
        rating: 4,
        bonus: 'Free Spin Up to $1,500 Redeem Code » CSGamblingCC',
        description: 'CSGOEmpire is a CS2 Roulette Site with instant P2P CS2 skin withdrawals and many cool game modes to choose from.',
        link: 'https://csgoempire.com/r/CSGamblingCC',
        alt: 'CSGOEmpire logo'
    },
    {
        name: 'ClashGG',
        logo: 'https://pbs.twimg.com/profile_images/1603472685362778126/wdIhBgpP_400x400.jpg',
        rating: 4,
        bonus: 'Get +5% Extra on All Deposits Redeem Code » CSGAMBLING',
        description: 'Clash.gg is a new CS2 Case Opening Site with many cool game modes including Battle, Roulette, Upgrade, and Plinko! Withdraw real CS2 skins instantly! Get a 5% Bonus on all deposits and rakeback rewards.',
        link: 'https://clash.gg/r/csgambling',
        alt: 'ClashGG logo'
    },
    {
        name: 'Gamdom',
        logo: 'https://static.timesofisrael.com/www/uploads/2021/07/WhatsApp_Image_2021-07-19_at_11.25.03_PM_1.jpeg.jpg',
        rating: 4,
        bonus: 'Unlock Instant Rakeback Rewards Redeem Code » CSGAMBLING',
        description: 'Gamdom is a CS2 Gambling Site where you can play Crash, Roulette, and Slots and withdraw cryptocurrency!',
        link: 'https://www.gamdom.com/r/CSGAMBLING',
        alt: 'Gamdom logo'
    },
    {
      name: 'Duelbits',
      logo: 'https://pbs.twimg.com/profile_images/1651499066461650945/yju3QJN9_400x400.png',
      rating: 4,
      bonus: 'Crypto Sports Betting and Casino Redeem Code » CSGamblingCC',
      description: 'Duelbits is a huge Crypto Casino and CS2 Betting Site. You can play many games and withdraw Crypto. Use the code "CSGamblingCC."',
      link: 'https://duelbits.com/?a=CSGamblingCC',
      alt: 'Duelbits logo'
  },
  {
    name: 'Key-drop',
    logo: 'https://img-cdn.hltv.org/eventlogo/lGv1pIMDC0xmLfmLkjh_8s.png?ixlib=java-2.1.0&s=e775ba2e3211591276ed3e2d61861595',
    rating: 3.5,
    bonus: 'Get $0.55 for Free Redeem Code » CSGamblingCC',
    description: 'Key-drop is one of the most popular CS gambling sites, where its mainly based on opening cases. You can get $0.55 for free using the code "CSGamblingCC."',
    link: 'https://key-drop.com/?code=CSGAMBLINGCC',
    alt: 'Key-drop logo'
},
    {
        name: 'Hypedrop',
        logo: 'https://s3-eu-west-1.amazonaws.com/tpd/logos/5c87e17e3ccaa8000178b9cc/0x0.png',
        rating: 3.5,
        bonus: 'Open three free cases using our promo code >> CSGamblingCC!',
        description: 'Hypedrop is a cool Crypto-based fully fledge case opening site with provably fair and Upgrade and Case Battle modes. Made by same owners as CSGORoll',
        link: 'https://hypedrop.com/r/CSGamblingCC',
        alt: 'Hypedrop logo'
    },
    
];
  return (
    <div>
    <div className="casino-container">
      <div className="Header-content">
  <h1>Best CS2 Gambling Sites of 2023: Your Ultimate Guide</h1>
  <p>Discover the Best CS2 Gambling Sites for 2023 With Our Comprehensive List: Your One-Stop Resource for Top-Rated CS2 Gambling Experiences.</p>
  <div className="button-container">
  <Link className="link-color" to="/learnmore"><button className="header-button">Learn More</button></Link>
    <Link className="link-color" to="/advertise"><button className="header-button">Advertise With Us</button></Link>
    
  </div>
</div>

      <h2 className="Header-content">The Top Choices for the Best CS Skin Gambling Sites in 2023</h2>
      <div className="casino-card-container">
      {casinos.map((casino, index) => (
        <div className="casino-card" key={index}>
          <img src={casino.logo || 'default_logo.png'} alt={`${casino.name} logo`} className="casino-logo" />
          <h4 className="casino-name">{casino.name}</h4>
          <div className="casino-description">{casino.description || 'No description available'}</div>
          <div className="casino-rating">
            {casino.rating ? (
              <>
                <span className="rating-label">Rating:</span>
                <div>{generateStars(casino.rating)}</div>
              </>
            ) : (
              <span className="rating-label">No rating</span>
            )}
          </div>
          <div className="casino-bonus-container">
            <span className="bonus-label">
             <p>Bonus</p></span>
            <span className="casino-bonus">
            <strong>{casino.bonus || 'No bonus available'}</strong>
            </span>
          </div>
          <a href={casino.link || '#'} target="_blank" rel="noopener noreferrer">
            <button>Visit Site</button>
          </a>
        </div>
      ))}
      </div>
    </div>
    </div>
  );
  };
  
  export default CasinoRatings;
  