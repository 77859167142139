import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                
                <div className="footer-section">
                    <h4 className="footer-title">Services</h4>
                    <Link to="/game-modes" className="footer-small-title">Game Modes</Link>
                    <Link to="/advertise" className="footer-small-title">Advertise</Link>
                    <Link to="/terms-of-service" className="footer-small-title">Terms of Service</Link>
                    <Link to="/gamblingsites" className="footer-small-title">Gambling Sites</Link>
                </div>
                
                <div className="footer-section">
    <a href="https://www.begambleaware.org/" target="_blank" rel="noopener noreferrer">
        <img alt="BeGamblingAware Logo" src="https://skinlords.com/wp-content/uploads/2023/03/begambleaware-org-18-plus-only.png"/>
    </a>
</div>
            </div>
            <div className="social-media-icons">
                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                    <FacebookIcon style={{ color: 'white' }} />
                </a>
                <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
                    <TwitterIcon style={{ color: 'white' }} />
                </a>
                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                    <InstagramIcon style={{ color: 'white' }} />
                </a>
                <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                    <YouTubeIcon style={{ color: 'white' }} />
                </a>
            </div>
        </div>
    );
};

export default Footer;
