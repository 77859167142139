import React from 'react';
import { Link } from 'react-router-dom';

const RatingCriteria = () => {
  return (
    <div className="text-container">
      <div className="text-card-container">
        <div className="text-card">
          <h2 className="header-marginbot">How do we rate CS gambling sites?</h2>
          
          <h3 className="header-left">Game Modes</h3>
          <span className="text-marginbot">
            One of the foremost criteria for rating a CS gambling site is the variety and quality of game modes it offers. Sites with multiple game modes like Roulette, Crash, and Coinflip generally receive higher ratings. The user experience, including the graphics and interface, also plays a crucial role.
          </span>

          <h3 className="header-left">Payment Options</h3>
          <span className="text-marginbot">
            A good CS gambling site should offer multiple payment options for both deposits and withdrawals. This includes traditional methods like credit cards and modern options like cryptocurrencies. The speed and reliability of these transactions are also evaluated.
          </span>

          <h3 className="header-left">Trust and Reputation</h3>
          <span className="text-marginbot">
            Trustworthiness is paramount when it comes to online gambling. We scrutinize user reviews, the site's history, and its overall reputation within the community to assess its reliability.
          </span>

          <h3 className="header-left">License and Regulation</h3>
          <span className="text-marginbot">
            Sites that are licensed and regulated by credible authorities receive higher ratings. A license ensures that the site adheres to strict guidelines and regulations, which usually translates to a safer gambling environment.
          </span>

          <h3 className="header-left">Provably Fair System</h3>
          <span className="text-marginbot">
            A provably fair system is essential to ensure that all games are transparent and fair to the players. We check for the provably fair badge and test the system when possible to confirm its legitimacy.
          </span>

          <h3 className="header-left">User Interface and Experience</h3>
          <span className="text-marginbot">
            A clean, easy-to-navigate user interface enhances the overall user experience. Sites that are mobile-friendly and offer a smooth experience across different devices score extra points.
          </span>

          <h3 className="header-left">Customer Support</h3>
          <span className="text-marginbot">
            Effective customer support is vital for resolving issues and queries. We rate sites based on the efficiency, friendliness, and availability of their customer service.
          </span>

          <h3 className="header-left">Bonuses and Promotions</h3>
          <span className="text-marginbot">
            Bonuses and promotions are the cherries on top of a good CS gambling site. We evaluate the generosity, fairness, and frequency of bonuses and promotions offered.
          </span>
          <div className="button-container">
  <Link className="link-color" to="/gamblingsites"><button className="header-button">List of sites</button></Link>
    <Link className="link-color" to="/advertise"><button className="header-button">Advertise With Us</button></Link>
    
  </div>
        </div>
        
      </div>
    </div>
  );
};

export default RatingCriteria;
