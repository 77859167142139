import React from 'react';

const TermsOfService = () => {
  const darkModeStyles = {
    backgroundColor: 'black',
    color: 'white',
    fontFamily: 'Arial, sans-serif',
    padding: '20px'
  };

  return (
    <div className="text-bg" style={darkModeStyles}>
      <div className="text-container">
        <div className="text-card-container">
          <div className="text-card">
           
            <h1 className="header-marginbot">Terms of Service for CSGambling.cc</h1>
            <p>Find the Best CS Gambling Sites on CSGambling</p>
            <p><i>Last Updated: [30.09.2023]</i></p>

            <h2 className="header-left">Definitions</h2>
            <ul>
              <li><strong>CSGambling.cc:</strong> Hereinafter referred to as the "Site," "Website," "CSGambling.cc," or "we," "us," or "our."</li>
              <li><strong>Ownership of CSGambling.cc:</strong> Referred to as the "Ownership," "We," "Us," or "Our."</li>
              <li><strong>Users:</strong> Individuals, entities, or organizations who access or utilize services provided by CSGambling.cc are referred to as "Users," "You," or "Your."</li>
              <li><strong>This Document:</strong> The terms, conditions, and guidelines outlined herein are collectively referred to as "Terms of Service," "Terms," or "Agreement."</li>
              <li><strong>Content:</strong> Refers to all text, graphics, audio, video, data, and other information available on CSGambling.cc.</li>
            </ul>
            
            <h2 className="header-left">Acceptance of Terms of Service</h2>
            <p>By accessing or using the services provided by CSGambling.cc, you unequivocally agree to abide by these Terms of Service. This constitutes a legally binding agreement between you and CSGambling.cc, encompassing all stipulated terms herein without alteration, omission, or addition.</p>

            <h2 className="header-left" >Third-Party Websites</h2>
            <p>CSGambling.cc disclaims all responsibility for content hosted on external websites not under its purview. While our Site may feature links to third-party websites, including those offering gambling services, CSGambling.cc neither exercises control over, nor assumes liability for, the content, products, or services offered by these third-party platforms.</p>

            <h2 className="header-left" >Disclaimers</h2>
            <p>We reserve the right to update or modify CSGambling.cc and its Terms of Service at our discretion and without prior notice. Under no circumstances shall CSGambling.cc, its Ownership, or affiliated parties be held liable for any direct or indirect losses or damages arising from the use of our Site, its content, or any linked third-party websites. It is worth noting that some external links on our Site lead to gambling platforms. Engaging with such sites is entirely at your own risk; we assume no responsibility for any financial or material losses incurred.</p>
                    
            <h2 className="header-left">Compliance with Local Laws</h2>
            <p>You are solely responsible for ensuring that your use of CSGambling.cc is in compliance with applicable local laws. Certain jurisdictions prohibit activities related to CS:GO gambling, trading, investing, and betting. If you reside in a country where these activities are restricted or illegal, you should refrain from accessing third-party sites linked from our Site, unless such use is consistent with your local laws.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;