import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';  // Import Link and useNavigate from react-router-dom
import './Navbar.css';

const CustomNavbar = () => {
  const navigate = useNavigate();  // Hook to programmatically navigate
  
  const handleDropdownClick = (path) => {
    navigate(path);
  };

  return (
    <Navbar className="navbar" expand="lg">
      <div className="navbar-inner"> {/* New inner container */}
        <Navbar.Brand as={Link} to="/"> {/* Use 'as={Link}' and 'to' */}
          <h2>
            <span style={{ color: '#f0b40c' }}>CS</span>
            <span style={{ color: 'white' }}>Gambling.CC</span>
          </h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-right mr-auto">
            <Nav.Link as={Link} className="nav-item" to="/advertise">Advertise</Nav.Link> {/* Use 'as={Link}' and 'to' */}
            <NavDropdown title="Gambling Sites" id="basic-nav-dropdown">
              
              <NavDropdown.Item className="nav-item" onClick={() => handleDropdownClick("/csgoroll")}>CSGORoll</NavDropdown.Item> 
              <NavDropdown.Item className="nav-item" onClick={() => handleDropdownClick("/csgoempire")}>CSGOEmpire</NavDropdown.Item>
              <NavDropdown.Item className="nav-item" onClick={() => handleDropdownClick("/clashgg")}>ClashGG</NavDropdown.Item> 
              <NavDropdown.Item className="nav-item" onClick={() => handleDropdownClick("/500casino")}>500 Casino</NavDropdown.Item>
              <NavDropdown.Item className="nav-item" onClick={() => handleDropdownClick("/gamdom")}>Gamdom</NavDropdown.Item> 
              <NavDropdown.Item className="nav-item" onClick={() => handleDropdownClick("/keydrop")}>Key-drop</NavDropdown.Item> 
              <NavDropdown.Item className="nav-item" onClick={() => handleDropdownClick("/hypedrop")}>Hypedrop</NavDropdown.Item> 
              <NavDropdown.Item className="nav-item" onClick={() => handleDropdownClick("/duelbits")}>Duelbits</NavDropdown.Item> 
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default CustomNavbar;