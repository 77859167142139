import React from 'react';
import './TextHomepage.css';
import { Link } from 'react-router-dom';
import "./Advertise.css"

const GameMode = () => {
    return (
        <div>
            <div className="text-bg">
    <div className="text-container">
        <div className="text-card-container">
            <div className="text-card">
                <h2 className="header-marginbot">Explore the Best CS Gambling Sites: A Comprehensive Guide to Game Modes</h2>
                
                <h3 className="header-left">Introduction to CS Gambling Game Modes</h3>
                <span className="text-marginbot">Dive into the fascinating world of CS Gambling with a wide array of game modes designed to cater to all types of players. From classic options like CS2 Roulette to more adrenaline-pumping modes like CS2 Crash, there's something for everyone.</span>

                
                <h3 className="header-left">CS2 Roulette: A Classic Reinvented</h3>
                <span className="text-marginbot">CS2 Roulette takes the timeless appeal of roulette and simplifies it for a streamlined experience. Unlike traditional roulette, you can bet only on red, green, or black, making it more accessible for newcomers while retaining the excitement for veterans.</span>
                <img className="img-text-full" src="https://media.discordapp.net/attachments/985159131184898058/1157386694698487858/image.png?ex=65186bdb&is=65171a5b&hm=2f9bd627282b48011a79b7cda061a5e3a25352f326c00ddeda3b8cb55aa2f46d&=&width=768&height=145" alt="Roulette gambling game"/>
                <span className="text-marginbot-left">
    Gambling sites with Roulette: 
    <Link className="link-color" to="https://500.casino/r/CSGAMBLINGCC">500 Casino</Link>{' '}
    <Link className="link-color" to="https://csgoroll.com/r/CSGamblingCC">CSGORoll</Link>{' '} 
    <Link className="link-color" to="https://csgoempire.com/r/CSGamblingCC">CSGOEmpire</Link>{' '}  
    <Link className="link-color" to="https://clash.gg/r/csgambling">ClashGG</Link>{' '}  
    <Link className="link-color" to="https://www.gamdom.com/r/CSGAMBLING">Gamdom</Link>{' '}  
    <Link className="link-color" to="https://duelbits.com/?a=CSGamblingCC">Duelbits</Link>{' '}  
</span>
                
                <h3 className="header-left">CS2 Crash: The Ultimate Risk and Reward Game</h3>
                <span className="text-marginbot">CS2 Crash offers a unique blend of suspense and excitement. Watch a multiplier increase in real-time but be prepared to cash out before the inevitable crash. It's a game of nerves, strategy, and a little bit of luck.</span>
                <img  className="img-text-full" src="https://skinlords.com/wp-content/uploads/2023/09/clash-gg-crash-original-gamemode.jpg" alt="Crash gambling game"/>
                <span className="text-marginbot-left">
    Gambling sites with Crash: 
    <Link className="link-color" to="https://500.casino/r/CSGAMBLINGCC">500 Casino</Link>{' '}
    <Link className="link-color" to="https://csgoroll.com/r/CSGamblingCC">CSGORoll</Link>{' '} 
    <Link className="link-color" to="https://clash.gg/r/csgambling">ClashGG</Link>{' '}  
    <Link className="link-color" to="https://www.gamdom.com/r/CSGAMBLING">Gamdom</Link>{' '}  
    <Link className="link-color" to="https://duelbits.com/?a=CSGamblingCC">Duelbits</Link>{' '}  
</span>

                <h3 className="header-left">CS Sports Betting: Where Skill Meets Luck</h3>
                <span className="text-marginbot">Engage in CS Sports Betting to test your knowledge of e-sports scenes. Whether you're a fan of CS2 or other e-sports titles, there are ample opportunities to place wagers and win big based on your insights and predictions.</span>
                <img className="img-text-full" src="https://cdn.discordapp.com/attachments/985159131184898058/1157387218835472475/image.png?ex=65186c58&is=65171ad8&hm=2498e5cc4e356890de910170142100105fa5317516e83af1a0b602fa4efd6c38&" alt="CS Sports Betting"/>
                <span className="text-marginbot-left">
                        Gambling sites with sportsbetting: 
                        <Link className="link-color" to="https://500.casino/r/CSGAMBLINGCC">500 Casino</Link>{' '}
                        <Link className="link-color" to="https://csgoempire.com/r/CSGamblingCC">CSGOEmpire</Link>{' '}  
                        <Link className="link-color" to="https://csgoroll.com/r/CSGamblingCC">CSGORoll</Link>{' '} 
                        <Link className="link-color" to="https://clash.gg/r/csgambling">ClashGG</Link>{' '}  
                        <Link className="link-color" to="https://www.gamdom.com/r/CSGAMBLING">Gamdom</Link>{' '}  
                        <Link className="link-color" to="https://duelbits.com/?a=CSGamblingCC">Duelbits</Link>{' '}  
                </span>

                <h3 className="header-left">Case Opening: A Treasure Hunt Experience</h3>
                <span className="text-marginbot">The Case Opening mode offers the thrill of unboxing, where each case could reveal a treasure trove of CS2 skins. With a variety of cases available, each opening is a new adventure.</span>
                <img className="img-text-full" src="https://imgnew.outlookindia.com/uploadimage/library/free_files/png/image4_2023_07_14_114246.png" alt="CS Case Opening"/>
                <span className="text-marginbot-left">
                    Gambling sites with Case opening:{' '}
                    <Link className="link-color" to="https://csgoroll.com/r/CSGamblingCC">CSGORoll</Link>{' '}
                    <Link className="link-color" to="https://clash.gg/r/csgambling">ClashGG</Link>{' '}
                    <Link className="link-color" to="https://key-drop.com/?code=CSGAMBLINGCC">Key-drop</Link>{' '}
                    <Link className="link-color" to="https://hypedrop.com/r/CSGamblingCC">Hypedrop</Link>
                </span>
                <h3 className="header-left">Battles: Player vs Player Action</h3>
                <span className="text-marginbot">In Battles, players go head-to-head in various mini-games. From coin flips to more intricate challenges, Battles offer a social and competitive aspect that many find exhilarating.</span>
                <img className="img-text-full" src="https://counterstrikeskinwager.com/wp-content/uploads/2023/08/clash-gg-case-battle-1024x559.png" alt="Case Battles"/>
                <span className="text-marginbot-left">
                    Gambling sites with Case battles:{' '}
                    <Link className="link-color" to="https://csgoroll.com/r/CSGamblingCC">CSGORoll</Link>{' '}
                    <Link className="link-color" to="https://clash.gg/r/csgambling">ClashGG</Link>{' '}
                    <Link className="link-color" to="https://key-drop.com/?code=CSGAMBLINGCC">Key-drop</Link>{' '}
                    <Link className="link-color" to="https://hypedrop.com/r/CSGamblingCC">Hypedrop</Link>
                </span>
                <h3 className="header-left">Casino Games: Traditional Gameplay in a CS2 Universe</h3>
                <span className="text-marginbot">For those who prefer classic casino experiences, many CS Gambling Sites offer traditional games like Blackjack and Slots. Enjoy the casino atmosphere right from your screen, all within the CS2 ecosystem.</span>
                <img className="img-text-full" src="https://cdn.discordapp.com/attachments/985159131184898058/1157387637171171428/image.png?ex=65186cbb&is=65171b3b&hm=f9a5c47454aeea3fde7e21113947630d337a437c0807bd0826bd4fea80104296&" alt="Casino Games"/>
                <span className="text-marginbot-left">
                    Gambling sites with a full casino:{' '}
                    <Link className="link-color" to="https://500.casino/r/CSGAMBLINGCC">500 Casino</Link>{' '}
                    <Link className="link-color" to="https://www.gamdom.com/r/CSGAMBLING">Gamdom</Link>{' '}
                    <Link className="link-color" to="https://duelbits.com/?a=CSGamblingCC">Duelbits</Link>{' '}
                </span>
                <h3 className="header-left">Coinflip: A Game of Pure Chance</h3>
            <span className="text-marginbot">
                Coinflip is a quintessential game of chance where each player has a 50/50 shot at victory. Flip the coin and hold your breath. It's a simple yet exhilarating way to put your luck to the test.
            </span>
            <img className="img-text-full" src="https://www.esportsbettingtipster.com/wp-content/uploads/2020/12/csgo-flipcoin.jpg" alt="Coinflip"/>
            <span className="text-marginbot-left">
            Gambling sites with Coinflip:{' '}
                    <Link className="link-color" to="https://csgoempire.com/r/CSGamblingCC">CSGOEmpire</Link>{' '}
                    <Link className="link-color" to="https://500.casino/r/CSGAMBLINGCC">500 Casino</Link>{' '}
                </span>
                

            <h3 className="header-left">Upgrader: Transform Your Inventory</h3>
            <span className="text-marginbot">
                Upgrader allows you to breathe new life into your CS2 inventory. The concept is straightforward: use your existing items as a stepping stone to unlock something more valuable. This game mode adds a strategic layer to your gambling experience.
            </span>
            <img className="img-text-full" src="https://i.ytimg.com/vi/vXmAM0tvObQ/maxresdefault.jpg" alt="Upgrader"/>
            <span className="text-marginbot-left">
                    Gambling sites with Case battles:{' '}
                    <Link className="link-color" to="https://csgoroll.com/r/CSGamblingCC">CSGORoll</Link>{' '}
                    <Link className="link-color" to="https://clash.gg/r/csgambling">ClashGG</Link>{' '}
                    <Link className="link-color" to="https://key-drop.com/?code=CSGAMBLINGCC">Key-drop</Link>{' '}
                    <Link className="link-color" to="https://hypedrop.com/r/CSGamblingCC">Hypedrop</Link>
                </span>

            <h3 className="header-left">Plinko: Navigate to Victory</h3>
            <span className="text-marginbot">
                Plinko takes you on a journey of uncertainty and excitement. Drop a ball and watch it dance between pegs as it makes its way to a prize slot. Each drop is a unique ballet of chaos and order, making it a fascinating game to both play and watch.
            </span>
            <img className="img-text-full" src="https://www.esports-betting.pro/app/uploads/2022/08/CSGORoll-Plinko.jpg" alt="Plinko"/>
            <span className="text-marginbot-left">
                    Gambling sites with Case battles:{' '}
                    <Link className="link-color" to="https://csgoroll.com/r/CSGamblingCC">CSGORoll</Link>{' '}
                    <Link className="link-color" to="https://clash.gg/r/csgambling">ClashGG</Link>{' '}
                    <Link className="link-color" to="https://500.casino/r/CSGAMBLINGCC">500 Casino</Link>{' '}
                    <Link className="link-color" to="https://duelbits.com/?a=CSGamblingCC">Duelbits</Link>
                </span>

            <h3 className="header-left">Mines: A Battlefield of Strategy</h3>
            <span className="text-marginbot">
                Mines offers an intricate blend of strategy and luck. Uncover cells on a grid, trying to avoid hidden mines. Each safe cell you reveal multiplies your stake, but hitting a mine will end the game. It's a thrilling exercise in risk management.
            </span>
            <img className="img-text-full" src="https://counterstrikeskinwager.com/wp-content/uploads/2023/08/clashgg-mines-1024x598.png" alt="Mines"/>
            <span className="text-marginbot-left">
                    Gambling sites with Case battles:{' '}
                    <Link className="link-color" to="https://duelbits.com/?a=CSGamblingCC">Duelbits</Link>{' '}
                    <Link className="link-color" to="https://clash.gg/r/csgambling">ClashGG</Link>{' '}
                </span>
            </div>
        </div>
    </div>
</div>
        </div>
        
    );
};

export default GameMode;