import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CustomNavbar from './Navbar';
import CasinoRatings from './CasinoRatings';
import SkinRating from './Skin';
import Footer from "./Footer";
import TermsOfService from "./TermsOfService"; 
import CSGORollReview from './Reviews/Roll';
import AdvertisingContact from "./AdvertisingContact";
import ClashGGReview from './Reviews/ClashGG';
import CSGOEmpireReview from './Reviews/CSGOEmpire';
import GamdomReview from './Reviews/Gamdom';
import FIVEcasino from './Reviews/500';
import DuelbitsReview from './Reviews/Duelbits';
import WtfSkinsReview from './Reviews/WTFSkinsReview'; // Import the new component
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import KeyDropReview from './Reviews/Keydrop';
import RatingCriteria from './RatingCriteria';
import GameMode from './GameMode';
import GamblingSite from './GamblingSites';
import { Helmet } from 'react-helmet';
import NotFound from './NotFound';

const Home = () => {
  return (
    <div>
      <CasinoRatings />
      <SkinRating />
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <CustomNavbar />
      <Routes>
        <Route path="/" element={
          <>
            <Helmet>
              <title>The Best CS Gambling sites - CSGambling.cc</title>
              <meta name="description" content="Discover top-rated CS gambling sites. Get free bonuses and exclusive CS2 skins. Start betting on CS2 games with confidence on our curated list."/>
            </Helmet>
            <Home />
          </>
        } />
        <Route path="/terms-of-service" element={
          <>
            <Helmet>
              <title>TOS - CSGambling.CC</title>
              <meta name="description" content="Read the Terms of Service for CSGambling.cc, your guide to the best CS gambling sites. Learn about user responsibilities, third-party links, and legal compliance" />
            </Helmet>
            <TermsOfService />
          </>
        } />
        <Route path="/advertise" element={
          <>
            <Helmet>
              <title>Advertise on out site - CSGambling.CC</title>
              <meta name="description" content="Over 500 daily users trust us for the best CS:GO skin betting sites. Tailored advertising packages for the CS:GO gambling industry. Bet with confidence." />
            </Helmet>
            <AdvertisingContact />
          </>
        } />
        <Route path="/clashgg" element={
          <>
            <Helmet>
              <title>ClashGG Promo code 2023 - Get free deposit bonus!</title>
              <meta name="description" content="Unlock exclusive 2023 deposit bonuses with ClashGG promo codes. Get the edge you need in CS gambling today!" />
            </Helmet>
            <ClashGGReview />
          </>
        } />
        <Route path="/500casino" element={
          <>
            <Helmet>
              <title>500Casino Promo code 2023 - Get free deposit bonus!</title>
              <meta name="description" content="Secure your 2023 free deposit bonus at 500Casino. Don't miss out on top CS gambling offers!" />
            </Helmet>
            <FIVEcasino />
          </>
        } />
        <Route path="/csgoroll" element={
          <>
            <Helmet>
              <title>CSGORoll Promo code 2023 - Get free cases!</title>
              <meta name="description" content="Experience the thrill of CS gambling with free cases. Use our CSGORoll promo code for 2023!" />
            </Helmet>
            <CSGORollReview />
          </>
        } />
        <Route path="/csgoempire" element={
          <>
            <Helmet>
              <title>CSGOEmpire Promo code 2023 - Get free deposit bonus!</title>
              <meta name="description" content="Maximize your CS gambling strategy with a free deposit bonus at CSGOEmpire. Claim your 2023 promo code now!" />
            </Helmet>
            <CSGOEmpireReview />
          </>
        } />
        <Route path="/gamdom" element={
          <>
            <Helmet>
              <title>Gamdom Promo code 2023 - Instant rakeback!</title>
              <meta name="description" content="Elevate your CS gambling game with instant rakebacks. Use our 2023 Gamdom promo code!" />
            </Helmet>
            <GamdomReview />
          </>
        } />
        <Route path="/hypedrop" element={
          <>
            <Helmet>
              <title>Hypedrop Promo code 2023 - Get free cases!</title>
              <meta name="description" content="Ready to win big? Use our 2023 Hypedrop promo code to get free cases and start your CS gambling journey!" />
            </Helmet>
            <WtfSkinsReview />
          </>
        } />
        <Route path="/duelbits" element={
          <>
            <Helmet>
              <title>Duelbits Promo code 2023 - Get free deposit bonus!</title>
              <meta name="description" content="Unlock your 2023 free deposit bonus at Duelbits. Your next level in CS gambling starts here!" />
            </Helmet>
            <DuelbitsReview />
          </>
        } />
        <Route path="/keydrop" element={
          <>
            <Helmet>
              <title>Keydrop Promo code 2023 - Get free deposit bonus!</title>
              <meta name="description" content="Get a free deposit bonus for 2023 at Keydrop. Make your CS gambling experience unforgettable!" />
            </Helmet>
            <KeyDropReview />
          </>
        } />
        <Route path="/learnmore" element={
          <>
            <Helmet>
              <title>Learn More about CS Gambling</title>
              <meta name="description" content="Your comprehensive guide to CS gambling. Understand the game modes, strategies, and get expert reviews." />
            </Helmet>
            <RatingCriteria />
          </>
        } />
        <Route path="/game-modes" element={
          <>
            <Helmet>
              <title>CS Gambling Game Modes</title>
              <meta name="description" content="Explore various CS gambling game modes to enhance your betting strategy. Learn to play like a pro!" />
            </Helmet>
            <GameMode />
          </>
        } />
        <Route path="/gamblingsites" element={
          <>
            <Helmet>
              <title>All Gambling Sites - CSGamblingCC</title>
              <meta name="description" content="Discover a curated list of the best CS gambling sites in 2023. Make informed choices and bet wisely" />
            </Helmet>
            <GamblingSite />
          </>
        } />
        <Route path="*" element={
          <>
            <Helmet>
              <title>404 Page not found - CSGamblingCC</title>
              <meta name="description" content="Oops! The page you're looking for doesn't exist. Return to CSGambling.CC to continue your CS gambling journey." />
            </Helmet>
            <NotFound />
          </>
        } />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};


export default App;